import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { Event, EventType } from '../../../generated/types';
import { Colors } from '../../../styles/colors';

import { Form } from '../../../components/atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { TextInput } from '../../atoms/Form/TextInput';
import { SelectInput } from '../../../components/atoms/Form/SelectInput/SelectInput';
import { ChevronRightIcon } from '../../atoms/Icons';
import { PlacesAutocompleteInput } from '../../atoms/Form/PlacesAutocompleteInput';
import { Copy } from '../../atoms/Typography';

export const AddEventModal = ({ event, handleCancel, handleSubmit }: AddEventModalProps) => {
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    handleSubmit(data, event ? event : undefined);
  };

  return (
    <div>
      {event ? <H2>Edit Event</H2> : <H2>Add Event</H2>}
      <InfoAlert>
        {event ? (
          <Copy>You can edit the event to your calendar in relation to this Job Opportunity.</Copy>
        ) : (
          <Copy>You can add event to your calendar in relation to this Job Opportunity.</Copy>
        )}
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <SelectInput name="type" noMargin={true} defaultValue={event?.type || EventType.Face2Face} label="Type">
          <option value={EventType.Face2Face}>Face to face</option>
          <option value={EventType.PhoneCall}>Phone call</option>
          <option value={EventType.VideoCall}>Video call</option>
          <option value={EventType.Other}>Other</option>
        </SelectInput>
        <TextInput
          name="description"
          fullWidth
          type="text"
          label="Description"
          defaultValue={event?.description || ''}
          noMargin={true}
        />
        <TextInput
          name="startAt"
          fullWidth
          type="datetime-local"
          label="Start"
          required
          defaultValue={event?.startAt.slice(0, 16) || ''}
          InputLabelProps={{ shrink: true }}
          noMargin={true}
        />
        <TextInput
          name="endAt"
          fullWidth
          type="datetime-local"
          label="End"
          required
          defaultValue={event?.endAt.slice(0, 16) || ''}
          InputLabelProps={{ shrink: true }}
          noMargin={true}
        />
        <TextInput
          name="meetingUrl"
          fullWidth
          type="text"
          label="Meeting URL"
          defaultValue={event?.meetingUrl || ''}
          noMargin={true}
        />
        <PlacesAutocompleteInput
          defaultValue={event?.location || ''}
          name="location"
          label="Location"
          placeholder="Where is the event?"
        />

        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>
          {event ? (
            <PrimaryButton type="submit" inline iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
              Update
            </PrimaryButton>
          ) : (
            <PrimaryButton type="submit" inline iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
              Add
            </PrimaryButton>
          )}
        </WrapperButtons>
      </Form>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  description: Yup.string().max(100, 'Too Long!'),
  startAt: Yup.string().required('The start date is required'),
  endAt: Yup.string().required('The end date is required'),
  type: Yup.string().required('The type of the event is required'),
});

type FormData = Yup.InferType<typeof validationSchema>;

interface AddEventModalProps {
  event?: Event | null;
  handleSubmit: (a: any, e?: Event) => void;
  handleCancel: (a: any) => void;
}
